import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { setEdit, dialogOpen } from '../../Redux/ProductRedux';

function HouseTable() {
  const dispatch = useDispatch();
  const { allHouseProducts } = useSelector((state) => state.ProductManagements);

  const [isLoading, setIsLoading] = useState(false);

  const handleEdit = (id) => {
    dispatch(setEdit(id));
    dispatch(dialogOpen(true));
  };

  const columns = [
    {
      width: 50,
      renderCell: (params) => (
        <div className='row d-flex'>
          <div className='col-12'>
            <EditNoteIcon
              sx={{ color: '#1F75FE', cursor: 'pointer' }}
              onClick={() => {
                handleEdit(params.row.id);
              }}
            />
          </div>
        </div>
      ),
    },
    { field: 'productName', headerName: 'Product Name', width: 170 },
    { field: 'productCode', headerName: 'Product Code', width: 100 },
    { field: 'description', headerName: 'Description', width: 300 },
    { field: 'manufacturer', headerName: 'Manufacturer', width: 170 },
    { field: 'productType', headerName: 'Product Type', width: 170 },
    { field: 'packSize', headerName: 'Pack Size', width: 100 },
    { field: 'unitsPer', headerName: 'Units Per Box/Case', width: 100 },
    { field: 'productBox', headerName: 'Product Box', width: 100 },
    { field: 'productCase', headerName: 'Product Case', width: 100 },
    {
      field: 'actualPrice',
      headerName: 'Actual Price Of (Box/Case)',
      width: 100,
    },
    {
      field: 'actualPriceUnit',
      headerName: 'Actual Price Of (Unit)',
      width: 100,
    },
    { field: 'sellingPrice', headerName: 'Selling Price', width: 100 },
    { field: 'quantityInUnits', headerName: 'Quantity(in units)', width: 100 },
    {
      field: 'manufacturersRecommendation',
      headerName: 'Manufacturers Recommendation In Brief',
      width: 300,
    },
    {
      field: 'levelOfAdoption',
      headerName: 'Level Of Adoption Scale(1-5)',
      width: 80,
    },

    { field: 'treatmentFor', headerName: 'Treatments', width: 500 },
  ];
  const rows = allHouseProducts.map((product, index) => {
    // const suitableForType = product.suitableForType
    //   .map((item) => item.value)
    //   .join(', ');
    const treatmentFor = product.treatmentFor
      .map((item) => item.value)
      .join(', ');
    return {
      id: product._id,
      productName: product.productName,
      productCode: product.productCode,
      description: product.description,
      manufacturer: product.manufacturer,
      productType: product.productType,
      packSize: product.packSize,
      unitsPer: product.unitsPer,
      productBox: product.productBox,
      productCase: product.productCase,
      actualPrice: product.actualPrice,
      sellingPrice: product.sellingPrice,
      quantityInUnits: product.quantityInUnits,
      manufacturersRecommendation: product.manufacturersRecommendation,
      levelOfAdoption: product.levelOfAdoption,
      actualPriceUnit: product.actualPriceUnit,
      treatmentFor: treatmentFor,
    };
  });

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{
          display: 'flex',
          borderBottom: '1px solid #E0E0E0',
          padding: '10px',
        }}>
        <GridToolbarExport printOptions={{ disableToolbarButton: true }}/>
        <div style={{ flex: 1 }} />
        <GridToolbarQuickFilter />
      </GridToolbarContainer>
    );
  }

  return (
    <div className='container'>
      <Box>
        <DataGrid
          rows={rows}
          columns={columns}
          autoHeight
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          slotProps={{
            toolbar: {
              showQuickFilter: true,
            },
          }}
          pageSizeOptions={[10, 50, 100]}
          pagination
          loading={isLoading}
          getRowId={(row) => row.id}
          sx={{
            '& .low-quantity': {
              backgroundColor: 'rgba(255, 0, 0, 0.2)',
            },
            '& .MuiDataGrid-row:hover': {
              backgroundColor: '#f5f5f5',
            },
          }}
          getRowClassName={(params) =>
            params.row.quantity < 10 ? 'low-quantity' : ''
          }
        />
      </Box>
    </div>
  );
}

export default HouseTable;
