/* eslint-disable no-unused-vars */

import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import LoginPage from './components/LoginPage/LoginPage';
import ForgotPasswordPage from './components/ForgotPasswordPage/ForgotPasswordPage';
import DashboardPage from './components/DashboardPage/DashboardPage';
import StaffInfo from './components/StaffInfo/StaffInfo';
import PatientsInfo from './components/PatientInfo/Patientnfo';
import DataManagement from './components/DataManagement/DataManagement';
import AssesmentManagement from './components/AssesmentManagement/AssesmentManagement';
import TreatmentCategory from './components/TreatmentCategory/TreatmentCategory';
import TreatmentSuggestion from './components/TreatmentSuggestion/TreatmentSuggestion';
import DescriptionManagement from './components/DescriptionManagement/DescriptionManagement';
import HouseProducts from './components/HouseProduct/HouseProducts';
import MarketProducts from './components/MarketProducts/MarketProducts.js';

const PrivateRoute = ({ element }) => {
  const isAuthenticated = sessionStorage.getItem('accessToken');
  return isAuthenticated ? element : <Navigate to='/' />;
};

const RouteConfig = () => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<LoginPage />} />
          <Route path='/Forgot-Password' element={<ForgotPasswordPage />} />
          <Route
            path='/dashboard'
            element={<PrivateRoute element={<DashboardPage />} />}
          />
          <Route
            path='/staffs'
            element={<PrivateRoute element={<StaffInfo />} />}
          />
          <Route
            path='/patients'
            element={<PrivateRoute element={<PatientsInfo />} />}
          />
          <Route
            path='/datamanagement'
            element={<PrivateRoute element={<DataManagement />} />}
          />
          <Route
            path='/assesment'
            element={<PrivateRoute element={<AssesmentManagement />} />}
          />
          <Route
            path='/treatmentcategory'
            element={<PrivateRoute element={<TreatmentCategory />} />}
          />
          <Route
            path='/treatmentsuggestion'
            element={<PrivateRoute element={<TreatmentSuggestion />} />}
          />
          <Route
            path='/descriptionmanagement'
            element={<PrivateRoute element={<DescriptionManagement />} />}
          />
          <Route
            path='/inhouseproducts'
            element={<PrivateRoute element={<HouseProducts />} />}
          />
          <Route
            path='/marketproducts'
            element={<PrivateRoute element={<MarketProducts />} />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default RouteConfig;
