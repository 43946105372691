/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import PageLayout from '../Layout/Layout';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTreatment } from '../../Redux/TreatmentRedux';
import { NewaddButton } from '../Common/Tools/Buttons';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {
  dialogOpen,
  getAllMarketProducts,
  setEdit,
} from '../../Redux/ProductRedux.js';
import MarketTable from './MarketTable.js';
// import AddHouseProduct from './AddHouseProduct';

const MarketProducts = () => {
  const dispatch = useDispatch();
  const { isDialogOpen } = useSelector((state) => state.ProductManagements);

  useEffect(() => {
    dispatch(getAllMarketProducts());
    dispatch(getAllTreatment());
  }, []);

  const handleClose = () => {
    dispatch(setEdit(0));
    dispatch(dialogOpen(false));
  };

  const HandleAdd = () => {
    dispatch(dialogOpen(true));
  };

  return (
    <PageLayout>
      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <div className='col-12 col-md-12 col-sm-12 d-md-block'>
          <div className='row py-3 '>
            <p className='heading'>Market Products</p>
          </div>
          <div className='row px-4'>
            <div className='col-12 d-flex justify-content-end   pb-2'>
              <NewaddButton type='submit' onClick={HandleAdd}>
                Add New
              </NewaddButton>
            </div>
          </div>
          <div className='row pt-2'>
            <div className='col-md-12'>
              <MarketTable />
            </div>
          </div>
        </div>
      </Box>
      <Dialog open={Boolean(isDialogOpen)} fullWidth maxWidth={'md'}>
        <div>
          <IconButton
            aria-label='close'
            onClick={handleClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon sx={{ color: '#000' }} />
          </IconButton>
        </div>
        <DialogContent>
          <div className='row d-flex p-3'>{/* <AddHouseProduct /> */}</div>
        </DialogContent>
      </Dialog>
    </PageLayout>
  );
};

export default MarketProducts;
